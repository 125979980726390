<template>
    <div id="about" class="screen">
        <div class="container">
            <div class="tabs is-centered">
                <ul>
                    <li>
                        <a href="#characters">Characters</a>
                    </li>
                    <li>
                        <a href="#setting">Setting</a>
                    </li>
                    <li>
                        <a href="#author">Author</a>
                    </li>
                    <!-- <li>
                        <a href="#thanks">Thanks</a>
                    </li> -->
                </ul>
            </div>
        </div>

        <section id="characters" class="section">
            <div class="container">
                <div class="content">
                    <h1 class="title">The characters</h1>

                    <div class="columns is-multiline is-mobile">
                        <div class="
                                column
                                is-half-desktop is-full-tablet is-full-mobile
                            ">
                            <div id="jaar" class="columns mt-5">
                                <div class="column is-narrow">
                                    <router-link to="/about/jaar"><img class="frame"
                                            src="@/assets/img/char-jaar-icon.jpg" /></router-link>
                                </div>
                                <div class="column">
                                    <h2 class="subtitle">Captain Jaar</h2>
                                    <p>Age unknown, she/her</p>
                                    <blockquote class="balloon">
                                        All the rumors about me are true.
                                    </blockquote>
                                    <p>
                                        Witch, pirate, crazy lady who collects
                                        children in her traveling house on top
                                        of a giant bug named Huis. Why does she
                                        travel from town to town? What is she up
                                        to? And why do the mermaids hate her?
                                    </p>
                                    <p>
                                        <router-link to="/about/jaar">
                                            <i class="fa-solid fa-id-card" />
                                            Character art
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="
                                column
                                is-half-desktop is-full-tablet is-full-mobile
                            ">
                            <div id="roto" class="columns mt-5">
                                <div class="column is-narrow">
                                    <router-link to="/about/roto"><img class="frame"
                                            src="@/assets/img/char-roto-icon.jpg" /></router-link>
                                </div>
                                <div class="column">
                                    <h2 class="subtitle">Roto</h2>
                                    <p>14 years old, he/him</p>
                                    <blockquote class="balloon">
                                        I'm the oldest that means I'm in charge!
                                    </blockquote>
                                    <p>
                                        The eldest of Jaar’s kids, Roto is full
                                        of bravado while looking out for his
                                        found family. He takes is role of
                                        “leader” very seriously. Maybe too
                                        seriously since no one else thinks he’s
                                        in charge of them.
                                    </p>
                                    <p>
                                        <router-link to="/about/roto">
                                            <i class="fa-solid fa-id-card" />
                                            Character art
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="
                                column
                                is-half-desktop is-full-tablet is-full-mobile
                            ">
                            <div id="lylah" class="columns mt-5">
                                <div class="column is-narrow">
                                    <router-link to="/about/lylah">
                                        <img class="frame" src="@/assets/img/char-lylah-icon.jpg" />
                                    </router-link>
                                </div>
                                <div class="column">
                                    <h2 class="subtitle">Lylah</h2>
                                    <p>11 years old, they/them</p>
                                    <blockquote class="balloon">
                                        It's not junk! I haven't fixed it yet.
                                    </blockquote>
                                    <p>
                                        A mechanic, tinkerer, breaker of things,
                                        fixer, and
                                        <strike>junk</strike> treasure
                                        collector. Lylah dreams of becoming the
                                        best restorer in the world, or at least
                                        on this side of the world.
                                    </p>
                                    <p>
                                        <router-link to="/about/lylah">
                                            <i class="fa-solid fa-id-card" />
                                            Character art
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="
                                column
                                is-half-desktop is-full-tablet is-full-mobile
                            ">
                            <div id="ollyhicks" class="columns mt-5">
                                <div class="column is-narrow">
                                    <router-link to="/about/ollyhicks">
                                        <img class="frame" src="@/assets/img/char-olly-icon.jpg" />
                                    </router-link>
                                </div>
                                <div class="column">
                                    <h2 class="subtitle">Olly Hicks</h2>
                                    <p>11 years old, they/them</p>
                                    <blockquote class="balloon">
                                        I like money.
                                    </blockquote>
                                    <p>
                                        They’re a bit obsessed with money, and
                                        hand-make talismans to sell to the
                                        tourists as “genuine mermaid luck
                                        charms”, with mixed success.
                                    </p>
                                    <p>
                                        <router-link to="/about/ollyhicks">
                                            <i class="fa-solid fa-id-card" />
                                            Character art
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="
                                column
                                is-half-desktop is-full-tablet is-full-mobile
                            ">
                            <div id="dadelik" class="columns mt-5">
                                <div class="column is-narrow">
                                    <router-link to="/about/dadelik">
                                        <img class="frame" src="@/assets/img/char-dadelik-icon.jpg" />
                                    </router-link>
                                </div>
                                <div class="column">
                                    <h2 class="subtitle">Dadelik</h2>
                                    <p>10 years old, she/her</p>
                                    <blockquote class="balloon">
                                        Eat this and tell me what you think.
                                    </blockquote>
                                    <p>
                                        She loves to discover and grow herbs on
                                        the deck of the ship house. And test her
                                        new recipes on the crew.
                                    </p>
                                    <p>
                                        <router-link to="/about/dadelik">
                                            <i class="fa-solid fa-id-card" />
                                            Character art
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="
                                column
                                is-half-desktop is-full-tablet is-full-mobile
                            ">
                            <div id="kua" class="columns mt-5">
                                <div class="column is-narrow">
                                    <router-link to="/about/kua">
                                        <img class="frame" src="@/assets/img/char-kua-icon.jpg" />
                                    </router-link>
                                </div>
                                <div class="column">
                                    <h2 class="subtitle">Kua</h2>
                                    <p>9 years old, he/him</p>
                                    <blockquote class="balloon">
                                        I didn't do it!
                                    </blockquote>
                                    <p>
                                        A tiny chaos gremlin. Kua likes to
                                        snoop, take other people’s things and is
                                        fiercely protective of his found family.
                                        He's the best tracker and hunter of the
                                        group, which comes in handy when they’re
                                        in the wilderness.
                                    </p>
                                    <p>
                                        <router-link to="/about/kua">
                                            <i class="fa-solid fa-id-card" />
                                            Character art
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="
                                column
                                is-half-desktop is-full-tablet is-full-mobile
                            ">
                            <div id="ochin" class="columns mt-5">
                                <div class="column is-narrow">
                                    <router-link to="/about/ochin">
                                        <img class="frame" src="@/assets/img/char-ochin-icon.jpg" />
                                    </router-link>
                                </div>
                                <div class="column">
                                    <h2 class="subtitle">
                                        Ochin &amp; Cheddar
                                    </h2>
                                    <p>6 years old, he/him</p>
                                    <blockquote class="balloon">
                                        It followed me home, can I keep it?
                                    </blockquote>
                                    <p>
                                        The youngest of the crew, Ochin is
                                        always “rescuing” critters to keep as
                                        pets. His sidekick is a jumping spider
                                        he calls Cheddar. Cheddar thinks the boy
                                        is his large spider wife and likes to
                                        dance and present gifts so his
                                        weird-looking spouse won't eat him. It's
                                        a spider thing.
                                    </p>
                                    <p>
                                        <router-link to="/about/ochin">
                                            <i class="fa-solid fa-id-card" />
                                            Character art
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="
                                column
                                is-half-desktop is-full-tablet is-full-mobile
                            ">
                            <div id="huis" class="columns mt-5">
                                <div class="column is-narrow">
                                    <router-link to="/about/huis">
                                        <img class="frame" src="@/assets/img/char-huis-icon.jpg" />
                                    </router-link>
                                </div>
                                <div class="column">
                                    <h2 class="subtitle">
                                        Huis the housebeast
                                    </h2>
                                    <p>It's a living.</p>
                                    <blockquote class="balloon">
                                        Click-click!
                                    </blockquote>
                                    <p>
                                        <router-link to="/about/huis">
                                            <i class="fa-solid fa-id-card" />
                                            Character art
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 id="episodes" class="mt-6 subtitle">Other Characters</h2>
                <ul>
                    <li v-if="episodeList[0]">
                        <router-link to="/about/episode-0">
                            Episode 0
                        </router-link>
                        - {{ episodeList[0].title }}
                    </li>
                    <li v-if="episodeList[1]">
                        <router-link to="/about/episode-1">
                            Episode 1
                        </router-link>
                        - {{ episodeList[1].title }}
                    </li>
                </ul>
            </div>
        </section>

        <div id="setting" class="container">
            <hr />
        </div>

        <section class="section shifting-ground">
            <div class="container">
                <div class="content">
                    <h1 class="title">The Setting</h1>

                    <div class="columns">
                        <div class="column">
                            <h2 class="subtitle">The Shifting Ground</h2>
                            <p>
                                The surface of the planet Puin is always in
                                motion, like a cloth being dragged across a
                                table. The speed is slower than a fast walk, but
                                this still makes traveling up stream (south)
                                much more difficult that going the other way. In
                                fact, if you sat down on the ground you could
                                travel from pole to pole in about a month. Puin
                                is a tiny planet.
                            </p>
                            <p>
                                Of course you'd have to hold your breath as it
                                pulled you under the sea, and then there are
                                other dangers along the way, such as valleys and
                                rain. Valleys collect boulders which tumble and
                                crush anyone and anything dragged into their
                                churn. Rain storms turn open ground into a thick
                                muddy sludge that can suck unwary travelers to
                                their doom. And that's if the wildlife doesn't
                                get you first.
                            </p>
                        </div>
                        <div class="column is-narrow">
                            <img class="frame" src="@/assets/img/planet-south.png" />
                            <img class="frame ml-2" src="@/assets/img/planet-north.png" />
                        </div>
                    </div>

                    <h2 class="subtitle">The Trash</h2>
                    <p>
                        On and under the moving dirt is treasure. And trash.
                        Mostly trash. Metal, stone, sludge, plastic, and glass
                        of every shape and size. Sometimes all combined into
                        mysterious and highly prized technology. Technology that
                        can be fixed with the right tools and skills. The trash
                        and treasure all comes out of the south pole (the Butt)
                        and drifts with the flow to be swallowed back down into
                        the north pole (the Mouth).
                    </p>

                    <h2 class="subtitle">The Wildlife</h2>
                    <p>
                        Humans are the only mammals on planet Puin. All other
                        life takes the form of giant insects, plants, fungi, and
                        whatever the merfolk are. Every lifeform has their own
                        way of dealing with the ground's constant motion.
                    </p>
                    <h2 class="subtitle">And Such</h2>
                    <p>
                        Seasons are geographical, with winter at the south pole,
                        warming to spring and summer-like climates as you travel
                        north. Because of the dense jungle covering the northern
                        hemisphere no one is quite sure what's up there, but
                        that never stopped a good story.
                    </p>
                </div>
            </div>
        </section>

        <div id="author" class="container">
            <hr />
        </div>

        <section class="section">
            <div class="container">
                <div class="content">
                    <h1 class="title">The Author</h1>
                    <p>
                        Andy (he/him) is a life-long dabbler in many things.
                        He's the author and artist on several comic anthologies
                        and creator of the webcomic
                        <a href="http://i-mummy.com/">I, Mummy</a>. He's been a
                        game designer, lead artist, and project manager on 18
                        published PC and GameBoy titles (<a href="https://www.imdb.com/name/nm5032007/">IMDb</a>), including
                        <em>Rugrats Adventure Game</em>,
                        <em>Arthur’s Reading Race</em>,
                        <em>Bear in the Big Blue House online</em>,
                        <em>The Cat in the Hat</em>,
                        <em>Batman Toxic Chill</em>, <em>Blues Clues</em>,
                        <em>Charlotte’s Web the movie</em>, and many others.
                        Andy has also been a wildlife photographer with a focus
                        on
                        <a href="https://flickr.com/photos/otterlove/albums/72157600033943211">insect macros</a>
                        (big surprise). His work has been published in
                        <em>Mac Fan</em> and <em>Sierra</em> magazines,
                        <em>10 Grossest Bugs</em>, <em>Hey Bug Doctor</em>, and
                        <em>Den Store Kaninboka</em> (a Norwegian rabbit care
                        guide). Andy currently designs user interfaces to train
                        AI to train humans. He resides in Oregon with his
                        family and farm animals.
                    </p>
                    <div class="buttons are-small">
                        <a rel="me" href="https://mastodon.art/@otterlove" class="button is-primary is-outlined">
                            <span class="icon"><i class="fa-brands fa-mastodon"></i></span>
                            <span>mastodon</span></a>
                        <a class="button is-primary is-outlined" href="https://www.tumblr.com/abocode" target="_blank">
                            <span class="icon"><i class="fa-brands fa-tumblr"></i></span>
                            <span>tumblr</span>
                        </a>
                        <a class="button is-primary is-outlined" href="https://www.pillowfort.social/AndyP" target="_blank">
                            <span>pillowfort</span>
                        </a>
                    </div>
                    <p>
                        <a href="/">The Lost Things Club</a> is drawn in
                        <a href="https://procreate.art/" target="_blank">Procreate</a>
                        on an iPad. <br />
                        This website and its embeded comic editor were
                        coded in Vue.js by the author.
                    </p>
                </div>
            </div>
        </section>
        <div id="thanks" class="container">
            <hr />
        </div>

        <section class="section">
            <div class="container">
                <div class="content">
                    <h1 class="title">Thanks</h1>
                    <p>
                        To Ange for putting up with manic idea interruptions and
                        being the first LTC beta reader. <br />
                        Special thanks to everyone at
                        <a href="https://www.spiderforest.com/" target="_blank"><i class="fa-solid fa-spider" />
                            <strong>SpiderForest</strong></a>
                        webcomic collective for their encouragement and
                        feedback.
                    </p>
                    <p>
                        <strong>C64 Pro</strong> font by
                        <a href="https://style64.org/c64-truetype" target="_blank">Style64</a>
                        | <strong>Got Heroin?</strong> font by
                        <a href="https://www.1001fonts.com/users/chrisx/" target="_blank">Chris Hansen</a>
                        | <strong>Boldly Missy</strong> font by
                        <a href="https://missymeyer.com/boldly-missy-free-font" target="_blank">Missy Meyer</a>
                        <br />
                    </p>
                    <router-link to="/" class="mt-5 button is-primary is-outlined">
                        <img class="mr-2" src="@/assets/img/favicon.png" style="width: 1.25em" />
                        Return home
                    </router-link>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>



<script>
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";

export default {
    name: "About",
    components: { Footer },
    data() {
        return {};
    },
    // mounted() {
    // window.scrollTo(0, 0);
    // },
    computed: {
        ...mapGetters(["episodeList"]),
    },
};
</script>

<style lang="scss" scoped>
img.frame {
    border-radius: 1000px;
    border: 3px solid white;
}

.shifting-ground {
    mask-image: linear-gradient(transparent 0%,
            black 50px,
            black calc(100% - 50px),
            transparent 100%);
    background: url(../assets/img/bg-1bit-trash-icons.png) center;
    background-position-y: 0px;
    background-size: 600px 600px;
    animation: movePixels 450s linear infinite;
}

@keyframes movePixels {
    0% {
        background-position-y: 1600px;
    }

    100% {
        background-position-y: 0px;
    }
}
</style>
